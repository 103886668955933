import React from 'react';
import * as classNames from "@css/component/Cards.module.scss";
import Box from "@js/component/Box";
import FeaturedImage from "@js/component/FeaturedImage";
import CalendarIcon from "@js/component/icon/CalendarIcon";
import Heading from "@js/component/Heading";
import {Link} from "gatsby";

/**
 * @type NewsSingleCardsProps
 */
export type NewsSingleCardsProps = {
    id: number;
    url: string;
    image: string;
    title: string;
    date: string;
}

const NewsSingleCard = (props: NewsSingleCardsProps) => {
    const {
        id,
        url,
        image,
        title,
        date
    } = props;

    return (
        <Link
            className={ classNames.link }
            to={`/labelnieuws/${ url }`}
            key={ id }
        >
            <Box className={ classNames.box }>
                <FeaturedImage
                    gatsbyImageData={ image }
                    variant="card"
                />
                <div className={ classNames.content }>
                    <span className={ classNames.date }>
                        <CalendarIcon className={ classNames.icon }/>
                        { date }
                    </span>
                    <Heading className={ classNames.heading } element="h2" variant="small">
                        { title }
                    </Heading>
                </div>
            </Box>
        </Link>
    );
}

export default NewsSingleCard;