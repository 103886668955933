import React from 'react';
import * as classNames from "@css/component/Cards.module.scss";
import clsx from "clsx";
import NewsSingleCard from "@js/component/NewsSingleCard";

/**
 * @type NewsCardsProps
 */
export type NewsCardsProps = {
    posts: [];
    excludeId?: number;
    variant?: string;
    type?: string;
}

const NewsCards = (props: NewsCardsProps) => {
    const {
        posts,
        excludeId,
        variant,
        type,
        ...restProps
    } = props;

    return (
        <div className={ clsx(classNames.cards, { [classNames.noMargin]: 'extraNews' == variant }) } { ...restProps }>
            { !type && posts
                .filter(post => post.node.id !== excludeId)
                .map(item =>
                    <NewsSingleCard
                        key={ item.node.id }
                        id={ item.node.id }
                        url={ item.node.slug }
                        title={ item.node.title }
                        image={ item.node.featuredImage?.node.localFile.childImageSharp.gatsbyImageData }
                        date={ item.node.date }
                    />
                )}

            { type == 'tags' && posts
                .map(item =>
                    <NewsSingleCard
                        key={ item.id }
                        id={ item.id }
                        url={ item.slug }
                        title={ item.title }
                        image={ item.featuredImage?.node.localFile.childImageSharp.gatsbyImageData }
                        date={ item.date }
                    />
                )}
        </div>
    );
};

export default NewsCards;